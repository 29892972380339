import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';

import * as Monitoringctions from './monitoring-device-page.actions';
import {DeviceFilters, SocketDeviceInfo} from './monitoring-device-page.models';
import * as MonitoringSelectors from './monitoring-device-page.selectors';
import {Tick} from 'highcharts';
import {
	StatusTicketTitleEnum,
	StatusTitleEmun
} from 'src/app/models/StatusItem';
import {Ticket} from '../../monitoring-store/+state/monitoring.models';

@Injectable()
export class MonitoringDevicePageFacade {
	readonly loaded$ = this.store.pipe(select(MonitoringSelectors.loaded));
	readonly error$ = this.store.pipe(select(MonitoringSelectors.error));

	readonly statisticLogForDevice$ = this.store.pipe(
		select(MonitoringSelectors.statisticLogForDevice)
	);
	readonly selectVenueMonitoring$ = this.store.pipe(
		select(MonitoringSelectors.selectVenueMonitoring)
	);
	readonly selectedDevice$ = this.store.pipe(
		select(MonitoringSelectors.selectedDevice)
	);
	readonly loadedLogs$ = this.store.pipe(
		select(MonitoringSelectors.loadedLogs)
	);

	readonly analyticsLoaded$ = this.store.pipe(
		select(MonitoringSelectors.analyticsLoaded)
	);
	readonly analyticsDevice$ = this.store.pipe(
		select(MonitoringSelectors.analyticsDevice)
	);
	readonly analyticsPagination$ = this.store.pipe(
		select(MonitoringSelectors.analyticsPagination)
	);
	readonly anyalyticsFiltersPeriod$ = this.store.pipe(
		select(MonitoringSelectors.anyalyticsFiltersPeriod)
	);
	readonly analyticsDeviceAllEvents$ = this.store.pipe(
		select(MonitoringSelectors.analyticsDeviceAllEvents)
	);
	readonly analyticsAllEventsLoaded$ = this.store.pipe(
		select(MonitoringSelectors.analyticsAllEventsLoaded)
	);
	readonly allOrdersLoaded$ = this.store.pipe(
		select(MonitoringSelectors.allOrdersLoaded)
	);
	readonly allOrders$ = this.store.pipe(select(MonitoringSelectors.allOrders));
	readonly allOrdersAnalitycsForAllDevices$ = this.store.pipe(
		select(MonitoringSelectors.allOrdersAnalitycsForAllDevices)
	);

	constructor(private store: Store) {}

	getStatisticDevices(filters: {
		from?: string;
		to?: string;
		deviceIds: string[];
	}) {
		this.store.dispatch(Monitoringctions.getStatistic(filters));
	}
	getVenueNames(venuesId: string[]) {
		this.store.dispatch(Monitoringctions.getVenueNames({venues: venuesId}));
	}
	getDevices(filters: DeviceFilters) {
		this.store.dispatch(Monitoringctions.getDevices(filters));
	}
	setDevicesFilters(filters: DeviceFilters) {
		this.store.dispatch(Monitoringctions.setFiltersDevices({filters}));
	}
	createTicket(ticket: {
		title: string;
		message: string;
		deviceId: string;
		venue: string;
		priority: string;
		author: {
			name: string;
			email?: string;
			phone?: string;
		};
	}) {
		this.store.dispatch(Monitoringctions.createTicket(ticket));
	}
	getTickets(deviceIds: string[]) {
		this.store.dispatch(Monitoringctions.getTickets({deviceIds}));
	}
	loadTicket(ticketId: string) {
		console.log(ticketId);
		this.store.dispatch(
			Monitoringctions.loadTicketWithConversation({ticketId})
		);
	}
	sendMessage(ticketId: string, message: {name: string; message: string}) {
		this.store.dispatch(
			Monitoringctions.sendMessageInConveration({ticketId, message})
		);
	}
	updateTicket(ticketId: string, status: string) {
		this.store.dispatch(Monitoringctions.updateTicket({ticketId, status}));
	}
	getDeviceById(deviceId: string) {
		this.store.dispatch(Monitoringctions.loadDeviceById({deviceId}));
	}
	loadStatiscticForDevice(
		deviceId: string,
		search?: string,
		period?: {from: string; to: string}
	) {
		this.store.dispatch(
			Monitoringctions.loadStatiscticForDevice({deviceId, search, period})
		);
	}
	loadVenue(venueId: string) {
		this.store.dispatch(Monitoringctions.loadVenue({venueId}));
	}
	changeAnalyticsPager(pager: {page: number; limit: number}) {
		this.store.dispatch(Monitoringctions.changeAnalyticsPagination(pager));
	}
	changeAnalyticsPerid(period: {from: string; to: string}) {
		this.store.dispatch(Monitoringctions.changeAnalyticsPeriod(period));
	}
	getAllEvents(data: {deviceId: string}) {
		this.store.dispatch(
			Monitoringctions.getAnalyticsAllEvents({deviceId: data.deviceId})
		);
	}
	getAnayticsToDevice(data: {deviceIds: string[]}) {
		this.store.dispatch(
			Monitoringctions.getAnalyticWithPagination({
				deviceIds: data.deviceIds
			})
		);
	}
	getAllOrder(data: {deviceId: string; from?: string; to?: string}) {
		this.store.dispatch(Monitoringctions.getAllOrders(data));
	}
	getOnlineAndOrdersStatistic(data: {venues: string[]}) {
		this.store.dispatch(Monitoringctions.getAnalyticsForOnlineAndOrders(data));
	}
	deleteDevice(deviceId: string) {
		this.store.dispatch(Monitoringctions.deleteDevice({deviceId}));
	}
	deleteTicketSoft(ticketId: string) {
		this.store.dispatch(Monitoringctions.deleteTicketSoft({ticketId}));
	}
	deleteTicketHard(ticketId: string) {
		this.store.dispatch(Monitoringctions.deleteTicketHard({ticketId}));
	}
	restoreTicket(ticket: Ticket) {
		this.store.dispatch(Monitoringctions.restoreTicket({ticket}));
	}
	selectedStatusDeviceFilter(value: StatusTitleEmun | null) {
		this.store.dispatch(Monitoringctions.selectedStatusDeviceFilter({value}));
	}

	selectedStatusTicketFilter(value: StatusTicketTitleEnum | null) {
		this.store.dispatch(Monitoringctions.selectedStatusTicketFilter({value}));
	}
	updateDeviceSocketEventInStore(value: SocketDeviceInfo) {
		this.store.dispatch(Monitoringctions.setDevicesSocketStatuses({value}));
	}
}
