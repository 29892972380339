import {createAction, props} from '@ngrx/store';
import {User} from 'src/app/models/User';

export const login = createAction(
	'[Login Page] Login',
	props<{email: string; password: string}>()
);
export const loginSuccess = createAction(
	'[Login Page] Load Login Success',
	props<{response: User}>()
);
export const loginFailure = createAction(
	'[Login Page] Load Login Failure',
	props<{error: any}>()
);

export const checkToken = createAction(
	'[Check Token] CheckToken',
	props<{token: string}>()
);
export const checkTokenSuccess = createAction(
	'[Check Token] CheckToken Success',
	props<{response: User}>()
);
export const checkTokenFailure = createAction(
	'[Check Token] CheckToken Failure',
	props<{error: null}>()
);

export const logout = createAction('[Logout Token] Logout');
