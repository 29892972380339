import {Injectable} from '@angular/core';

import {select, Store} from '@ngrx/store';
import {debounceTime, map} from 'rxjs/operators';
import {User} from 'src/app/models/User';

import * as AuthActions from './auth.actions';
import * as AuthSelectors from './auth.selectors';

@Injectable()
export class AuthFacade {
	readonly loaded$ = this.store.pipe(select(AuthSelectors.loaded));
	readonly userData$ = this.store.pipe(select(AuthSelectors.userData));
	readonly error$ = this.store.pipe(select(AuthSelectors.error));
	readonly isLoggedIn$ = this.userData$.pipe(
		debounceTime(500),
		map(userData => !!userData)
	);
	constructor(private store: Store) {}
	login(data: {email: string; password: string}) {
		this.store.dispatch(AuthActions.login(data));
	}
	logout() {
		this.store.dispatch(AuthActions.logout());
	}
	checkToken() {
		const storageValue = localStorage.getItem('user');

		if (storageValue) {
			const user = JSON.parse(storageValue ?? '') as User;

			this.store.dispatch(AuthActions.checkToken({token: user.refreshToken}));
		}
	}
}
