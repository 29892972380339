import {createReducer, on, Action} from '@ngrx/store';
import {User} from 'src/app/models/User';
import * as AuthActions from './auth.actions';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
	loaded: boolean;
	error: null | string;
	userData: null | User;
}

export const initialState: AuthState = {
	loaded: true,
	error: null,
	userData: null
};

const authReducer = createReducer(
	initialState,
	on(AuthActions.login, (state, _) => ({
		...state,
		userData: null,
		loaded: false,
		error: null
	})),
	on(AuthActions.loginSuccess, (state, {response}) => ({
		...state,
		userData: response,
		loaded: true
	})),
	on(AuthActions.loginFailure, (state, {error}) => ({
		...state,
		loaded: true,
		error: error
	})),
	on(AuthActions.checkToken, (state, _) => ({
		...state,
		loaded: false,
		error: null,
		userData: null
	})),
	on(AuthActions.checkTokenSuccess, (state, {response}) => ({
		...state,
		userData: response,
		loaded: true
	})),
	on(AuthActions.checkTokenFailure, (state, {error}) => ({
		...state,
		loaded: true,
		error: error,
		userData: null
	})),
	on(AuthActions.logout, state => ({
		...state,
		userData: null,
		loaded: true
	}))
);

export function reducer(state: AuthState | undefined, action: Action) {
	return authReducer(state, action);
}
