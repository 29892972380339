import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import * as MonitoringActions from './monitoring-device-page.actions';

import {
	map,
	tap,
	switchMap,
	catchError,
	withLatestFrom,
	concatMap
} from 'rxjs/operators';
import {of} from 'rxjs';

import {MonitoringDevicePageService} from './monitoring-device-page.service';
import {MonitoringDevicePageFacade} from './monitoring-device-page.facade';

@Injectable()
export class MonitoringDevicePageEffects {
	getVenueNames$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.getVenueNames),
			switchMap(venueIds => {
				return this.monitoringService.getVenuesName(venueIds.venues).pipe(
					map(response => {
						return MonitoringActions.getVenueNamesSuccess({
							response: response
						});
					}),
					catchError(error => {
						return of(MonitoringActions.getVenueNamesFailed({error}));
					})
				);
			})
		)
	);
	getStatistic$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.getStatistic),

			switchMap(filters => {
				return this.monitoringService.getDeviceStatistic(filters).pipe(
					map(response => {
						return MonitoringActions.getStatisticSuccess({response});
					}),
					catchError(error => {
						return of(MonitoringActions.getStatisticFailed({error}));
					})
				);
			})
		)
	);
	// reloadDevicesByFilters$ = createEffect(
	// 	() => {
	// 		return this.actions$.pipe(
	// 			ofType(MonitoringActions.setFiltersDevices),
	// 			tap(() => {
	// 				this.monitoringFacade.getDevices();
	// 			})
	// 		);
	// 	},
	// 	{dispatch: false}
	// );

	getStatisticWithLogForDevice$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.loadStatiscticForDevice),

			switchMap(filters => {
				console.log(filters);
				return this.monitoringService
					.loadStatisticForDeviceWithLogs(
						filters.deviceId,
						filters?.search,
						filters?.period
					)
					.pipe(
						map(response => {
							return MonitoringActions.loadStatiscticForDeviceSucc({
								response
							});
						}),
						catchError(error => {
							return of(
								MonitoringActions.loadStatiscticForDeviceFailed({error})
							);
						})
					);
			})
		)
	);
	getDeviceById$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.loadDeviceById),

			switchMap(filters => {
				return this.monitoringService.loadDeviceById(filters.deviceId).pipe(
					map(response => {
						return MonitoringActions.loadDeviceByIdSucc({
							response
						});
					}),
					catchError(error => {
						return of(MonitoringActions.loadDeviceByIdFailed({error}));
					})
				);
			})
		)
	);
	getVenueById$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.loadVenue),
			switchMap(result => {
				return this.monitoringService.getVenuesName([result.venueId]).pipe(
					map(response => {
						return MonitoringActions.loadVenueSucc({
							response: response[0]
						});
					}),
					catchError(error => {
						return of(MonitoringActions.loadVenueFailed({error}));
					})
				);
			})
		)
	);
	getAnalytics$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.getAnalyticWithPagination),
			withLatestFrom(
				this.monitoringFacade.selectedDevice$,
				this.monitoringFacade.analyticsPagination$,
				this.monitoringFacade.anyalyticsFiltersPeriod$
			),
			concatMap(([value, selectedDevice, pagination, period]) => {
				return this.monitoringService
					.getAnalyticsForDevice(
						[selectedDevice?._id ?? ''],
						period?.from,
						period?.to,
						pagination.page,
						pagination.limit
					)
					.pipe(
						map(response => {
							return MonitoringActions.getAnalyticWithPaginationSucc({
								response
							});
						}),
						catchError((error: any) => {
							return of(
								MonitoringActions.getAnalyticWithPaginationFailed({error})
							);
						})
					);
			})
		)
	);
	reloadAnalytics$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(
					MonitoringActions.changeAnalyticsPagination,
					MonitoringActions.changeAnalyticsPeriod
				),
				tap(() => {
					this.monitoringFacade.getAllEvents({deviceId: ''});
				})
			);
		},
		{dispatch: false}
	);
	getAllEvents$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.getAnalyticsAllEvents),
			withLatestFrom(
				this.monitoringFacade.selectedDevice$,
				this.monitoringFacade.analyticsPagination$,
				this.monitoringFacade.anyalyticsFiltersPeriod$
			),
			concatMap(([value, selectedDevice, pagination, period]) => {
				return this.monitoringService
					.getAllAnalyticsByDevice({
						deviceId: selectedDevice?._id ?? '',
						deviceNumber: selectedDevice?.number ?? '',
						from: period?.from,
						to: period?.to,
						venue: selectedDevice?.venue ?? '',
						page: pagination.page,
						limit: pagination.limit
					})
					.pipe(
						map(response => {
							return MonitoringActions.getAnalyticsAllEventsSucc({
								response
							});
						}),
						catchError((error: any) => {
							return of(MonitoringActions.getAnalyticsAllEventsFailed({error}));
						})
					);
			})
		)
	);
	getOrders$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.getAllOrders),
			switchMap(result => {
				return this.monitoringService
					.getAllOrdersForDevices({
						deviceId: result.deviceId,
						from: result?.from,
						to: result?.to
					})
					.pipe(
						map(response => {
							return MonitoringActions.getAllOrdersSucc({
								response: response
							});
						}),
						catchError(error => {
							return of(MonitoringActions.getAllOrdersFailed({error}));
						})
					);
			})
		)
	);
	analyticsVenuesOrdersAndOnline$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.getAnalyticsForOnlineAndOrders),
			switchMap(result => {
				return this.monitoringService
					.getOnlineAndOrdersStatistic(result.venues)
					.pipe(
						map(response => {
							return MonitoringActions.getAnalyticsForOnlineAndOrdersSucc({
								response: response
							});
						}),
						catchError(error => {
							return of(
								MonitoringActions.getAnalyticsForOnlineAndOrdersFailed({
									error
								})
							);
						})
					);
			})
		)
	);
	constructor(
		private actions$: Actions,
		private monitoringService: MonitoringDevicePageService,
		private monitoringFacade: MonitoringDevicePageFacade
	) {}
}
