import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {User} from '../models/User';
import {Observable} from 'rxjs';
import {Table} from '../models/Table';
import {Venue} from '../models/Venue';
import {
	Device,
	DeviceFiltersParams,
	DeviceResponseWithPagination,
	VenueName
} from '../store/monitoring-store/+state/monitoring.models';
import {environment} from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	constructor(private http: HttpClient) {}

	BASE_URL = environment.apiUrl;
	AUTH_URL = '/v1/auth';
	GENERAL_URL = '/v1/general';
	MONITORING_URL = '/v1/monitoring';

	login(data: {email: String; password: String}): Observable<User> {
		return this.http.post<User>(`${this.BASE_URL}${this.AUTH_URL}/login`, data);
	}

	refresh(refreshToken: string) {
		return this.http.post<User>(`${this.BASE_URL}${this.AUTH_URL}/refresh`, {
			refreshToken
		});
	}
	// get venue by customer group  ->
	getTablesVenue(id: string): Observable<Table[]> {
		return this.http.get<Table[]>(
			`${this.BASE_URL}${this.GENERAL_URL}/venue/${id}/tables`
		);
	}
	getVenue(id: string) {
		return this.http.get<Venue>(
			`${this.BASE_URL}${this.GENERAL_URL}/venue/${id}`
		);
	}
	getVenuesName(venueIds: string[]) {
		return this.http.post<VenueName[]>(
			`${this.BASE_URL}${this.GENERAL_URL}/venue/venuenames`,
			{
				venues: venueIds
			}
		);
	}
	getDeviceWithFilters(
		filters: DeviceFiltersParams
	): Observable<DeviceResponseWithPagination> {
		let params = new HttpParams({
			fromObject: {
				page: 1,
				limit: 10
			}
		});
		if (filters) {
			if (filters?.customerGroups && filters?.customerGroups?.length > 0) {
				params = params.append(
					'customerGroup',
					filters?.customerGroups.join(',')
				);
			}
			if (filters?.venues && filters?.venues?.length > 0) {
				filters.venues.forEach((id: string) => {
					params = params.append(`venues[]`, id.toString());
				});
			}
			if (filters?.deviceIds && filters?.deviceIds?.length > 0) {
				filters.deviceIds.forEach((id: string) => {
					params = params.append(`deviceIds[]`, id.toString());
				});
			}
			if (filters?.statuses && filters?.statuses?.length > 0) {
				params = params.append('statuses', filters?.statuses.join(','));
			}
			if (filters?.search) {
				params = params.append('search', filters?.search.join(','));
			}
		}
		return this.http.get<DeviceResponseWithPagination>(
			`${environment.apiUrl}${this.MONITORING_URL}/device/paginate`,
			{params}
		);
	}
}
