import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {initTE, Select} from 'tw-elements';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {NAVIGATIONURLS} from 'src/app/services/navigation.service';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit {
	constructor(
		private location: Location,
		private router: Router
	) {}
	nowDate() {
		const today = new Date().toLocaleString('en-US', {
			weekday: 'short',
			day: 'numeric',
			month: 'short',
			year: 'numeric'
		});
		return today;
	}

	ngOnInit(): void {
		initTE({Select});
	}
	back() {
		if (!this.router.url.endsWith(NAVIGATIONURLS.monitoring())) {
			this.location.back();
		}
	}
}
