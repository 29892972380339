import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import * as FiltersActions from './filters-data.actions';
import {map, tap, switchMap, catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {FiltersService} from './filters-data.service';

@Injectable()
export class FiltersEffects {
	loadCustomerGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FiltersActions.loadFiltersCustomerGroup),
			switchMap(() => {
				return this.filtersService.getAllCustomerGroups().pipe(
					map(response => {
						return FiltersActions.loadFiltersCustomerGroupSuccess({response});
					}),
					catchError((error: any) => {
						return of(FiltersActions.loadFiltersCustomerGroupFailed(error));
					})
				);
			})
		)
	);
	constructor(
		private actions$: Actions,
		private filtersService: FiltersService
	) {}
}
