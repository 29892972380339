<div
	class="flex items-center justify-between py-2 px-4 border-b border-border bg-white"
>
	<img
		class="cursor-pointer"
		src="../../../assets/arrow-left.svg"
		alt="back"
		(click)="back()"
	/>

	<div class="flex items-center py-1.5 px-3 border border-border rounded-lg">
		<img
			class="w-4 h-4 text-gray-400 mr-2"
			src="../../../assets/search.svg"
			alt="search"
		/>
		<input
			type="text"
			class="w-full bg-transparent focus:outline-none"
			placeholder="Search..."
		/>
	</div>
	<div class="flex">
		<img class="mr-2" src="../../../assets/Calandar.svg" alt="Calandar" />
		<p class="text-grey_text">{{ nowDate() }}</p>
	</div>
	<select data-te-select-init>
		<option value="1">EN</option>
		<option value="2">DE</option>
	</select>
</div>
