import {createAction, props} from '@ngrx/store';
import {
	AnalyticsResponse,
	Device,
	DeviceFilters,
	DeviceFiltersParams,
	DeviceStatistic,
	DeviceTypeEnum,
	getOnlineAndOrdersStatistic,
	OrderLogHistory,
	SocketDeviceInfo,
	Ticket,
	UptimeStatisticResponse,
	VenueName
} from './monitoring.models';
import {
	StatusTicketTitleEnum,
	StatusTitleEmun
} from 'src/app/models/StatusItem';

// LOAD ALL DEVICES
export const getDevices = createAction(
	'[Monitoring Page] Get List Devices',
	props<DeviceFilters>()
);
export const getDevicesSuccess = createAction(
	'[Monitoring Page] Get List Devices Success',
	props<{response: Device[]}>()
);
export const getDevicesFailed = createAction(
	'[Monitoring Page] Get List Devices Failed',
	props<{error: any}>()
);

// Add filters
export const setFiltersDevices = createAction(
	'[Monitoring Page] Set Filters for Device',
	props<{filters: DeviceFiltersParams}>()
);

// LOAD VENUE names which have user
export const getVenueNames = createAction(
	'[Monitoring Page] Get Venue Names',
	props<{venues: string[]}>()
);
export const getVenueNamesSuccess = createAction(
	'[Monitoring Page] Get Venue NamesSuccess',
	props<{response: VenueName[]}>()
);
export const getVenueNamesFailed = createAction(
	'[Monitoring Page] Get Venue Names Failed',
	props<{error: any}>()
);

//selected Venues by User
export const selectedVenues = createAction(
	'[Monitoring Page] Get Venue Names Failed',
	props<{venues: VenueName[]}>()
);
//selected Venues by User
export const deleteSelectedVenue = createAction(
	'[Monitoring Page] Get Venue Names Failed',
	props<{venueId: string}>()
);

// Statistic
export const getStatistic = createAction(
	'[Monitoring Page] Get List Statistic for Devices',
	props<{deviceIds: string[]; from?: string; to?: string}>()
);
export const getStatisticSuccess = createAction(
	'[Monitoring Page] Get List Statistic for Devices Success',
	props<{response: UptimeStatisticResponse[]}>()
);
export const getStatisticFailed = createAction(
	'[Monitoring Page] Get List Statistic for Devices Failed',
	props<{error: any}>()
);

// get Tickets
export const getTickets = createAction(
	'[Monitoring Page] Get List Tickets',
	props<{deviceIds: string[]}>()
);
export const getTicketsSuccesfull = createAction(
	'[Monitoring Page] Get List Tickets Successfull',
	props<{response: Ticket[]}>()
);
export const getTicketsFailed = createAction(
	'[Monitoring Page] Get List Tickets Failed',
	props<{error: any}>()
);

// create Tickets
export const createTicket = createAction(
	'[Monitoring Page] Create  Ticket',
	props<{
		title: string;
		message: string;
		deviceId: string;
		venue: string;
		author: {
			name: string;
			email?: string;
			phone?: string;
		};
	}>()
);
export const createTicketSuccesfull = createAction(
	'[Monitoring Page] Create Ticket Successfull',
	props<{response: Ticket}>()
);
export const createTicketFailed = createAction(
	'[Monitoring Page] Create Ticket Failed',
	props<{error: any}>()
);

// load Ticket With Conversation
export const loadTicketWithConversation = createAction(
	'[Monitoring Page] Load Conversation',
	props<{ticketId: string}>()
);
export const loadTicketWithConversationSuccessfull = createAction(
	'[Monitoring Page] Load Conversation Successfull',
	props<{response: Ticket}>()
);
export const loadTicketWithConversationFailed = createAction(
	'[Monitoring Page] Load Conversation Failed',
	props<{error: any}>()
);
// send Message in Coversation
export const sendMessageInConveration = createAction(
	'[Monitoring Page] Send Message Conversation',
	props<{ticketId: string; message: {name: string; message: string}}>()
);
export const sendMessageInConverationSuccessfull = createAction(
	'[Monitoring Page] Send Message Successfull',
	props<{response: Ticket}>()
);
export const sendMessageInConverationFailed = createAction(
	'[Monitoring Page] Send Message Failed',
	props<{error: any}>()
);

// update Ticket With New Status
export const updateTicket = createAction(
	'[Monitoring Page] Update Ticket Conversation',
	props<{ticketId: string; status: string}>()
);
export const updateTicketSucc = createAction(
	'[Monitoring Page] Update Ticket Successfull',
	props<{response: Ticket}>()
);
export const updateTicketFailed = createAction(
	'[Monitoring Page] Update Ticket  Failed',
	props<{error: any}>()
);

// update Ticket With New Status
export const loadDeviceById = createAction(
	'[Monitoring Page] Load Device By Id',
	props<{deviceId: string}>()
);
export const loadDeviceByIdSucc = createAction(
	'[Monitoring Page] Load Device By Id Successfull',
	props<{response: Device}>()
);
export const loadDeviceByIdFailed = createAction(
	'[Monitoring Page]  Load Device By Id Failed',
	props<{error: any}>()
);

// --- Monitoring PAGE For Speicific Device ----

// load  Monitoring Log For Specific Device
export const loadStatiscticForDevice = createAction(
	'[Monitoring Page] Load Statistic For Device By Id',
	props<{
		deviceId: string;
		search?: string;
		period?: {from: string; to: string};
	}>()
);
export const loadStatiscticForDeviceSucc = createAction(
	'[Monitoring Page] Load Statistic For Device Successfull',
	props<{response: DeviceStatistic[]}>()
);
export const loadStatiscticForDeviceFailed = createAction(
	'[Monitoring Page] Load Statistic For Device Failed',
	props<{error: any}>()
);

// load venue
export const loadVenue = createAction(
	'[Monitoring Page] Load Venue',
	props<{venueId: string}>()
);
export const loadVenueSucc = createAction(
	'[Monitoring Page] Load Venue Succ',
	props<{response: VenueName}>()
);
export const loadVenueFailed = createAction(
	'[Monitoring Page] Load Venue Failed',
	props<{error: any}>()
);

// get Analytic With Pagination
// deviceIds[]=64fb05a8230e47873eae2f71&from=2023-09-13T14:23:46.717&to=2023-09-13T14:23:46.717&page=1&limit=4&sort=asc&events[]=screenChange
export const getAnalyticWithPagination = createAction(
	'[Monitoring Page] Get Analytics with Pagination',
	props<{
		deviceIds: string[];

		pagination?: {page?: number; limit?: number};
		date?: {from?: string; to?: string};
	}>()
);
export const getAnalyticWithPaginationSucc = createAction(
	'[Monitoring Page] Get Analytics with Pagination Succ',
	props<{response: AnalyticsResponse}>()
);
export const getAnalyticWithPaginationFailed = createAction(
	'[Monitoring Page] Get Analytics with Pagination Failed',
	props<{error: any}>()
);
export const changeAnalyticsPagination = createAction(
	'[Monitoring Page]  Analytics Update to Pagination',
	props<{
		page: number;
		limit: number;
	}>()
);
export const changeAnalyticsPeriod = createAction(
	'[Monitoring Page]  Analytics Change Period',
	props<{
		from: string;
		to: string;
	}>()
);

// Load AllAnalyticsWithDifferentTypes
export const getAnalyticsAllEvents = createAction(
	'[Monitoring Page] Get Analytics with Pagination All Events',
	props<{
		deviceId: string;
	}>()
);
export const getAnalyticsAllEventsSucc = createAction(
	'[Monitoring Page] Get Analytics with Pagination All Events Succ',
	props<{response: any}>()
);
export const getAnalyticsAllEventsFailed = createAction(
	'[Monitoring Page] Get Analytics with Pagination All Events Failed',
	props<{error: any}>()
);

// Load AllAnalyticsWithDifferentTypes
export const getAllOrders = createAction(
	'[Monitoring Page] Get All Orders Events',
	props<{
		deviceId: string;
		from?: string;
		to?: string;
	}>()
);
export const getAllOrdersSucc = createAction(
	'[Monitoring Page] Get All Orders Succ',
	props<{response: OrderLogHistory[]}>()
);
export const getAllOrdersFailed = createAction(
	'[Monitoring Page] Get All Orders Failed',
	props<{error: any}>()
);

//get all online and payment Analytics
export const getAnalyticsForOnlineAndOrders = createAction(
	'[Monitoring Page] Get Analytics for Devices and Orders',
	props<{
		venues: string[];
	}>()
);
export const getAnalyticsForOnlineAndOrdersSucc = createAction(
	'[Monitoring Page] Get Analytics for Devices and Orders Succ',
	props<{response: getOnlineAndOrdersStatistic}>()
);
export const getAnalyticsForOnlineAndOrdersFailed = createAction(
	'[Monitoring Page] Get Analytics for Devices and Orders Failed',
	props<{error: any}>()
);

//get all online and payment Analytics
export const deleteDevice = createAction(
	'[Monitoring Page] Delete Device',
	props<{
		deviceId: string;
	}>()
);
export const deleteDeviceSucc = createAction(
	'[Monitoring Page] Delete Device Succ',
	props<{deviceId: string}>()
);
export const deleteDeviceFailed = createAction(
	'[Monitoring Page] Delete Device Failed',
	props<{error: any}>()
);

//get all online and payment Analytics
export const deleteTicketSoft = createAction(
	'[Monitoring Page] Delete Ticket Soft',
	props<{
		ticketId: string;
	}>()
);
export const deleteTicketSoftSucc = createAction(
	'[Monitoring Page] Delete Ticket Soft Succ',
	props<{ticketId: string}>()
);
export const deleteTicketSoftFailed = createAction(
	'[Monitoring Page] Delete Ticket Soft Failed',
	props<{error: any}>()
);

export const deleteTicketHard = createAction(
	'[Monitoring Page] Delete Ticket Hard',
	props<{
		ticketId: string;
	}>()
);
export const deleteTicketHardSucc = createAction(
	'[Monitoring Page] Delete Ticket Hard Succ',
	props<{ticketId: string}>()
);
export const deleteTicketHardFailed = createAction(
	'[Monitoring Page] Delete Ticket Hard Failed',
	props<{error: any}>()
);

export const restoreTicket = createAction(
	'[Monitoring Page] Restore Ticket',
	props<{
		ticket: Ticket;
	}>()
);
export const restoreTicketSucc = createAction(
	'[Monitoring Page] Restore Ticket Succ',
	props<{ticketId: string}>()
);
export const restoreTicketFailed = createAction(
	'[Monitoring Page] Restore Ticket  Failed',
	props<{error: any}>()
);

export const selectedStatusDeviceFilter = createAction(
	'[Monitoring Page] Select Status Of All Devices',
	props<{value: StatusTitleEmun | null}>()
);

export const selectedStatusTicketFilter = createAction(
	'[Monitoring Page] Select Status Of All Tickets',
	props<{value: StatusTicketTitleEnum | null}>()
);

export const setDevicesSocketStatuses = createAction(
	'[Monitoring Page] Set Device To Store From Socket',
	props<{value: SocketDeviceInfo}>()
);

export const showDuplicates = createAction(
	'[Monitoring Page] Show Duplicates',
	props<{value: {number: string; venueId: string} | null}>()
);
export const reset = createAction(
	'[Monitoring Page] Rest',
	props<{reset: any}>()
);
export const updateDeviceType = createAction(
	'[Monitoring Page] Update Device Type',
	props<{deviceId: string; deviceType: DeviceTypeEnum}>()
);
export const updateDeviceSuccess = createAction(
	'[Monitoring Page] Update Device Type Succ',
	props<{device: Device}>()
);
export const updateDeviceError = createAction(
	'[Monitoring Page] Update Device Type Fail',
	props<{error: any}>()
);
export const cleanAnalyticsForOnlineAndOrders = createAction(
	'[Monitoring Page] Clean Online And Orders Analytics'
);
