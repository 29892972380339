import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AUTH_FEATURE_KEY, AuthState} from './auth.reducer';

export const authState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);
export const userData = createSelector(
	authState,
	(state: AuthState) => state.userData
);
export const loaded = createSelector(
	authState,
	(state: AuthState) => state.loaded
);
export const error = createSelector(
	authState,
	(state: AuthState) => state.error
);
