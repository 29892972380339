import {Injectable} from '@angular/core';

import {select, Store} from '@ngrx/store';
import {debounceTime, map} from 'rxjs/operators';
import {User} from 'src/app/models/User';

import * as FitlersActions from './filters-data.actions';
import * as FiltersSelectors from './filters-data.selectors';

@Injectable()
export class FiltersFacade {
	readonly loaded$ = this.store.pipe(select(FiltersSelectors.loaded));
	readonly customerGroup$ = this.store.pipe(
		select(FiltersSelectors.customerGroups)
	);

	constructor(private store: Store) {}
	loadCustomerGroup() {
		this.store.dispatch(FitlersActions.loadFiltersCustomerGroup());
	}
	// login(data: { email: string; password: string }) {
	//   this.store.dispatch(AuthActions.login(data));
	// }
	// logout() {
	//   this.store.dispatch(AuthActions.logout());
	// }
	// checkToken() {
	//   const user = JSON.parse(localStorage.getItem('user') ?? '') as User;
	//   if (user) {
	//     this.store.dispatch(AuthActions.checkToken({ token: user.refreshToken }));
	//   }
	// }
}
