import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root'
})
export class TokenService {
	constructor(private cookieService: CookieService) {}

	static tokenKey = 'token';
	static refreshTokenKey = 'refreshToken';

	getToken() {
		return localStorage.getItem(TokenService.tokenKey);
	}

	saveToken(token: string) {
		localStorage.setItem(TokenService.tokenKey, token);
	}

	removeToken() {
		localStorage.removeItem(TokenService.tokenKey);
	}

	getRefreshToken() {
		return this.cookieService.get(TokenService.refreshTokenKey);
	}

	saveRefreshToken(refreshToken: string) {
		this.cookieService.set(
			TokenService.refreshTokenKey,
			refreshToken,
			30,
			'/',
			'',
			false,
			'Strict'
		);
	}

	removeRefreshToken() {
		this.cookieService.delete(TokenService.refreshTokenKey);
	}

	saveBothTokens(token: string, refreshToken: string) {
		this.saveToken(token);
		this.saveRefreshToken(refreshToken);
	}

	removeBothTokens() {
		this.removeToken();
		this.removeRefreshToken();
	}
}
