export enum CustomerGroupEnum {
	Default = '',
	Subway = 'subway',
	SubwayFr = 'subway_fr',
	SubwayLu = 'subway_lu',
	SubwayTerminal = 'subway_terminal',
	DB = 'db',
	Lunchroom = 'lunchroom',
	FoodByFriends = 'foodbyfriends',
	Weilands = 'weilands',
	Cinemaxx = 'cinemaxx',
	District = 'district',
	Slush = 'slush',
	Kochloeffel = 'kochloeffel',
	Immergruen = 'immergruen',
	MyMonza = 'mymonza',
	Aspasia = 'aspasia',
	Cotidiano = 'cotidiano',
	Bigburger = 'bigburger',
	KOOS = 'koos',
	Frittenwerk = 'frittenwerk',
	Ottosburger = 'ottosburger',
	Purino = 'purino',
	DeanAndDavid = 'dean_and_david',
	ThreeMensKebab = '3menskebab',
	PerfectDay = 'perfectday',
	Hausmanns = 'hausmanns',
	Doenerwerk = 'doenerwerk',
	Foodlounge = 'foodlounge',
	Mado = 'mado',
	Nordsee = 'nordsee',
	Depot = 'depot',
	Jamies = 'jamies',
	BurgerKing = 'burger_king',
	Saray = 'saray',
	Moschmosch = 'moschmosch',
	Starbucks = 'starbucks',
	Kamps = 'kamps',
	Coffee_Fellows = 'coffee_fellows',
	Hofbrauhaus = 'hofbrauhaus',
	Atlantik_Fisch = 'atlantik_fisch',
	DIMATEST = 'dima_test',
	MALOA = 'maloa',
	WERK5 = 'werk5',
	EPI = 'epi',
	IAMLOVE = 'iamlove',
	BEETS_AND_ROOTS = 'beets_and_roots',
	BEETS_AND_ROOTS_SECOND = 'beets_and_roots_second',
	LOSTERIA = 'losteria',
	SHISO = 'shiso',
	SNACK_ELIZE = 'snack_elize',
	TABILO = 'tabilo',
	EFFE_GOLD = 'effe_gold',
	LACANTINE = 'lacantine',
	EAM = 'eam',
	NAUPAKA = 'naupaka',
	SPORTSBAR = 'sportsbar',
	CIGKOFTEM = 'cigkoftem',
	SUSHISUPPLY = 'sushisupply',
	THE_ASH = 'the_ash',
	MARKTHALLE = 'markthalle',
	SASHIMI = 'sashimi',
	BONBONCHA = 'bonboncha',
	HULALA = 'hulala',
	BACKWERK_SCHWEIZ = 'backwerk_schweiz',
	TEEDELI = 'teedeli',
	WOHLFUHLER = 'wohlfuhler'
}
