import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import * as AuthActions from './auth.actions';
import {AuthService} from './auth.service';
import {map, tap, switchMap, catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {User} from 'src/app/models/User';

@Injectable()
export class AuthEffects {
	login$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.login),
			switchMap(token => {
				return this.authService
					.login({email: token.email, password: token.password})
					.pipe(
						map(response => {
							const user = localStorage.getItem('user');
							console.log(user);
							localStorage.setItem('user', JSON.stringify(response));
							return AuthActions.loginSuccess({response: response});
						}),
						catchError(error => {
							return of(AuthActions.loginFailure({error}));
						})
					);
			})
		)
	);
	logout$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.logout),
				tap(() => {
					localStorage.removeItem('token');
				})
			),
		{dispatch: false}
	);
	checkToken$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.checkToken),
			switchMap(token => {
				return this.authService.checkToken(token.token).pipe(
					map(response => {
						let storageValue = localStorage.getItem('user');

						if (storageValue) {
							const user = JSON.parse(storageValue ?? '') as User;
							user.refreshToken = response.refreshToken;
							user.token = response.token;
							localStorage.setItem('user', JSON.stringify(user));
						}
						const totalUser = JSON.parse(storageValue ?? '');

						return AuthActions.checkTokenSuccess({
							response: totalUser
						});
					}),
					catchError(error => {
						return of(AuthActions.checkTokenFailure({error}));
					})
				);
			})
		)
	);
	deleteToken$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.loginFailure, AuthActions.checkTokenFailure),
				tap(() => {
					localStorage.removeItem('token');
				})
			),
		{dispatch: false}
	);
	constructor(
		private actions$: Actions,
		private authService: AuthService
	) {}
}
