import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {User} from 'src/app/models/User';

@Component({
	selector: 'app-user-pin',
	templateUrl: './user-pin.component.html',
	styleUrls: ['./user-pin.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPinComponent {
	@Input() user: User | null;
}
