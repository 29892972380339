<div
	class="wrapper flex flex-col justify-between min-h-screen h-full pt-7 pr-2.5 pb-7 pl-5 bg-primary text-gray-100"
>
	<div>
		<img src="../../../assets/logo_white.svg" alt="logo" class="mb-7" />
		<ul class>
			<li
				*ngFor="let sidebarItem of sidebarItems"
				class="w-full cursor-pointer bg-opacity-25 py-2 px-4 rounded-lg hover:bg-opacity-20 hover:bg-white mb-2 last:mb-0"
				[routerLink]="sidebarItem.link"
				routerLinkActive="hover:bg-opacity-40 bg-white"
			>
				<div class="flex font-sans font-medium text-white">
					<img class="mr-2" [src]="sidebarItem.img" alt />
					{{ sidebarItem.title }}
				</div>
			</li>
		</ul>
	</div>

	<div class="sticky bottom-4 left-3">
		<app-user-pin [user]="user"></app-user-pin>
		<button
			(click)="logoutFunc()"
			class="flex items-center w-full bg-white bg-opacity-25 py-2 px-4 rounded-lg hover:bg-opacity-40 cursor-pointer"
		>
			<img src="../../../assets/logout.svg" alt="logout" class="mr-2" />
			<p class="text-white">Logout</p>
		</button>
	</div>
</div>
