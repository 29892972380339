import {createReducer, on, Action} from '@ngrx/store';

import * as MonitoringActions from './monitoring-device-page.actions';
import {
	AnalyticItem,
	DeviceStatistic,
	OrderLogHistory,
	VenueName
} from './monitoring-device-page.models';
import {Device} from '../../monitoring-store/+state/monitoring.models';

export const MONITORING_FEATURE_KEY = 'monitoring-table';

export interface MonitoringState {
	loaded: boolean;
	error: null | {status: string; ok: string; message: string};

	selectVenueMonitoring: VenueName | null;
	statisticLogForDevice: DeviceStatistic[];
	loadedLogs: boolean;
	selectedDevice: Device | null;
	analyticsPagination: {
		page: number;
		limit: number;
		total: number;
	};
	anyalyticsFiltersPeriod: {
		from: string;
		to: string;
	};
	analyticsDevice: AnalyticItem[];
	analyticsLoaded: boolean;
	analyticsDeviceAllEvents: any[];
	analyticsAllEventsLoaded: boolean;
	allOrders: OrderLogHistory[];
	loadedAllOrders: boolean;
	allOrdersAnalitycsForAllDevices: OrderLogHistory[];
}

export const initialState: MonitoringState = {
	loaded: true,
	error: null,

	selectVenueMonitoring: null,
	statisticLogForDevice: [],
	loadedLogs: true,
	selectedDevice: null,
	analyticsPagination: {
		page: 1,
		limit: 15,
		total: 10
	},
	analyticsDevice: [],
	analyticsLoaded: true,
	anyalyticsFiltersPeriod: {
		from: '',
		to: ''
	},
	analyticsAllEventsLoaded: true,
	analyticsDeviceAllEvents: [],
	allOrders: [],
	loadedAllOrders: true,
	allOrdersAnalitycsForAllDevices: []
};

const monitoringReducer = createReducer(
	initialState,

	on(MonitoringActions.getStatistic, (state, _) => ({
		...state,
		loaded: false,
		error: null,
		loadedStatistic: false,
		statisticForDevices: []
	})),
	on(MonitoringActions.getStatisticSuccess, (state, response) => ({
		...state,
		loaded: false,
		error: null,
		loadedStatistic: true,
		statisticForDevices: response.response
	})),
	on(MonitoringActions.getStatisticFailed, (state, error) => ({
		...state,
		loaded: false,
		error: error.error,
		loadedStatistic: true
	})),

	//loadVenue
	on(MonitoringActions.loadVenue, (state, response) => ({
		...state,
		selectVenueMonitoring: null,
		analyticsDevice: []
	})),
	on(MonitoringActions.loadVenueSucc, (state, response) => ({
		...state,
		selectVenueMonitoring: response.response
	})),
	on(MonitoringActions.loadVenueFailed, (state, error) => ({
		...state,
		error: error.error
	})),
	//load Device
	on(MonitoringActions.loadDeviceById, (state, response) => ({
		...state,
		selectedDevice: null
	})),
	on(MonitoringActions.loadDeviceByIdSucc, (state, response) => ({
		...state,
		selectedDevice: response.response,
		analyticsPagination: {
			page: 1,
			limit: 15,
			total: 10
		}
	})),
	on(MonitoringActions.loadDeviceByIdFailed, (state, error) => ({
		...state,
		error: error.error
	})),
	//load Device Statistic
	on(MonitoringActions.loadStatiscticForDevice, (state, response) => ({
		...state,
		statisticLogForDevice: [],
		loadedLogs: false
	})),
	on(MonitoringActions.loadStatiscticForDeviceSucc, (state, response) => ({
		...state,
		statisticLogForDevice: response.response,
		loadedLogs: true
	})),
	on(MonitoringActions.loadStatiscticForDeviceFailed, (state, error) => ({
		...state,
		error: error.error,
		loadedLogs: true
	})),

	//get Analytics
	on(MonitoringActions.getAnalyticWithPagination, (state, response) => ({
		...state,
		analyticsLoaded: false,
		analyticsDevice: []
	})),
	on(MonitoringActions.getAnalyticWithPaginationSucc, (state, response) => ({
		...state,
		analyticsLoaded: true,

		analyticsDevice: response.response?.data ?? [],
		analyticsPagination: {
			...state.analyticsPagination,
			total: response.response?.pagination?.total ?? 1
		}
	})),
	on(MonitoringActions.getAnalyticWithPaginationFailed, (state, error) => ({
		...state,
		error: error.error,
		analyticsLoaded: true
	})),
	on(MonitoringActions.changeAnalyticsPagination, (state, value) => ({
		...state,
		analyticsPagination: {
			...state.analyticsPagination,
			limit: value.limit,
			page: value.page
		}
	})),
	on(MonitoringActions.changeAnalyticsPeriod, (state, value) => ({
		...state,
		anyalyticsFiltersPeriod: {
			...state.anyalyticsFiltersPeriod,
			from: value.from,
			to: value.to
		}
	})),

	//all events
	on(MonitoringActions.getAnalyticsAllEvents, (state, response) => ({
		...state,
		analyticsDeviceAllEvents: [],
		analyticsAllEventsLoaded: false
	})),
	on(MonitoringActions.getAnalyticsAllEventsSucc, (state, response) => ({
		...state,
		analyticsLoaded: true,

		analyticsDeviceAllEvents: response.response.data,
		analyticsAllEventsLoaded: true,
		analyticsPagination: {
			...state.analyticsPagination,
			total: response.response?.pagination?.total ?? 1
		}
	})),
	on(MonitoringActions.getAnalyticsAllEventsFailed, (state, error) => ({
		...state,
		error: error.error,
		analyticsDeviceAllEvents: [],
		analyticsAllEventsLoaded: true
	})),

	//order history for device
	on(MonitoringActions.getAllOrders, (state, response) => ({
		...state,
		loadedAllOrders: false,
		allOrders: []
	})),
	on(MonitoringActions.getAllOrdersSucc, (state, response) => ({
		...state,
		loadedAllOrders: true,
		allOrders: response.response
	})),
	on(MonitoringActions.getAllOrdersFailed, (state, error) => ({
		...state,
		error: error.error,
		loadedAllOrders: false,
		allOrders: []
	})),
	//order history for all Devices and Venues
	on(MonitoringActions.getAnalyticsForOnlineAndOrders, (state, _) => ({
		...state,
		analyticsVenuesOrdersAndOnline: null,
		analyticsVenuesOrdersAndOnlineLoaded: false
	})),
	on(
		MonitoringActions.getAnalyticsForOnlineAndOrdersSucc,
		(state, response) => ({
			...state,
			analyticsVenuesOrdersAndOnline: response.response,
			analyticsVenuesOrdersAndOnlineLoaded: true
		})
	),
	on(
		MonitoringActions.getAnalyticsForOnlineAndOrdersFailed,
		(state, error) => ({
			...state,
			error: error.error,
			analyticsVenuesOrdersAndOnline: null,
			analyticsVenuesOrdersAndOnlineLoaded: true
		})
	),

	on(MonitoringActions.restoreTicketFailed, (state, error) => ({
		...state,
		error: error.error
	})),
	on(MonitoringActions.selectedStatusDeviceFilter, (state, response) => ({
		...state,
		selectedStatusDeviceFilter: response.value
	})),
	on(MonitoringActions.selectedStatusTicketFilter, (state, response) => ({
		...state,
		selectedStatusTicketFilter: response.value
	}))
);
export function reducer(state: MonitoringState | undefined, action: Action) {
	return monitoringReducer(state, action);
}
