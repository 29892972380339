import {delay} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {User} from 'src/app/models/User';
import {TranslateService} from '@ngx-translate/core';
import {CustomerGroupEnum} from 'src/app/models/CustomerGroups';

@Injectable({
	providedIn: 'root'
})
export class FiltersService {
	AUTH_URL = '/v1/auth';
	GENERAL_URL = '/v1/general';
	constructor(
		private http: HttpClient,
		private translate: TranslateService
	) {}

	getAllCustomerGroups(): Observable<{text: string; value: string}[]> {
		return of([
			{text: this.translate.instant('customerGroups.default'), value: ''},
			{
				text: this.translate.instant('customerGroups.subway'),
				value: CustomerGroupEnum.Subway
			},
			{
				text: this.translate.instant('customerGroups.subwayTerminal'),
				value: CustomerGroupEnum.SubwayTerminal
			},
			{
				text: this.translate.instant('customerGroups.subwayFr'),
				value: CustomerGroupEnum.SubwayFr
			},
			{
				text: this.translate.instant('customerGroups.subwayLu'),
				value: CustomerGroupEnum.SubwayLu
			},
			{
				text: this.translate.instant('customerGroups.db'),
				value: CustomerGroupEnum.DB
			},
			{
				text: this.translate.instant('customerGroups.lunchroom'),
				value: CustomerGroupEnum.Lunchroom
			},
			{
				text: this.translate.instant('customerGroups.fbf'),
				value: CustomerGroupEnum.FoodByFriends
			},
			{
				text: this.translate.instant('customerGroups.weilands'),
				value: CustomerGroupEnum.Weilands
			},
			{
				text: this.translate.instant('customerGroups.cinemaxx'),
				value: CustomerGroupEnum.Cinemaxx
			},
			{
				text: this.translate.instant('customerGroups.district'),
				value: CustomerGroupEnum.District
			},
			{
				text: this.translate.instant('customerGroups.slush'),
				value: CustomerGroupEnum.Slush
			},
			{
				text: this.translate.instant('customerGroups.kochloeffel'),
				value: CustomerGroupEnum.Kochloeffel
			},
			{
				text: this.translate.instant('customerGroups.immergruen'),
				value: CustomerGroupEnum.Immergruen
			},
			{
				text: this.translate.instant('customerGroups.myMonza'),
				value: CustomerGroupEnum.MyMonza
			},
			{
				text: this.translate.instant('customerGroups.aspasia'),
				value: CustomerGroupEnum.Aspasia
			},
			{
				text: this.translate.instant('customerGroups.cotidiano'),
				value: CustomerGroupEnum.Cotidiano
			},
			{
				text: this.translate.instant('customerGroups.bigburger'),
				value: CustomerGroupEnum.Bigburger
			},
			{
				text: this.translate.instant('customerGroups.koos'),
				value: CustomerGroupEnum.KOOS
			},
			{
				text: this.translate.instant('customerGroups.deanAndDavid'),
				value: CustomerGroupEnum.DeanAndDavid
			},
			{
				text: this.translate.instant('customerGroups.ottosBurger'),
				value: CustomerGroupEnum.Ottosburger
			},
			{
				text: this.translate.instant('customerGroups.frittenwerk'),
				value: CustomerGroupEnum.Frittenwerk
			},
			{
				text: this.translate.instant('customerGroups.purino'),
				value: CustomerGroupEnum.Purino
			},
			{
				text: this.translate.instant('customerGroups.threeMensKebab'),
				value: CustomerGroupEnum.ThreeMensKebab
			},
			{
				text: this.translate.instant('customerGroups.perfectDay'),
				value: CustomerGroupEnum.PerfectDay
			},
			{
				text: this.translate.instant('customerGroups.hausmanns'),
				value: CustomerGroupEnum.Hausmanns
			},
			{
				text: this.translate.instant('customerGroups.doenerwerk'),
				value: CustomerGroupEnum.Doenerwerk
			},
			{
				text: this.translate.instant('customerGroups.foodlounge'),
				value: CustomerGroupEnum.Foodlounge
			},
			{
				text: this.translate.instant('customerGroups.mado'),
				value: CustomerGroupEnum.Mado
			},
			{
				text: this.translate.instant('customerGroups.nordsee'),
				value: CustomerGroupEnum.Nordsee
			},
			{
				text: this.translate.instant('customerGroups.depot'),
				value: CustomerGroupEnum.Depot
			},
			{
				text: this.translate.instant('customerGroups.jamies'),
				value: CustomerGroupEnum.Jamies
			},
			{
				text: this.translate.instant('customerGroups.burgerKing'),
				value: CustomerGroupEnum.BurgerKing
			},
			{
				text: this.translate.instant('customerGroups.atlantikFisch'),
				value: CustomerGroupEnum.Atlantik_Fisch
			},
			{
				text: this.translate.instant('customerGroups.moschmosch'),
				value: CustomerGroupEnum.Moschmosch
			},
			{
				text: this.translate.instant('customerGroups.kamps'),
				value: CustomerGroupEnum.Kamps
			},
			{
				text: this.translate.instant('customerGroups.coffeeFellows'),
				value: CustomerGroupEnum.Coffee_Fellows
			},
			{
				text: this.translate.instant('customerGroups.hofbrauhaus'),
				value: CustomerGroupEnum.Hofbrauhaus
			},
			{
				text: this.translate.instant('customerGroups.saray'),
				value: CustomerGroupEnum.Saray
			},
			{
				text: this.translate.instant('customerGroups.starbucks'),
				value: CustomerGroupEnum.Starbucks
			},
			{
				text: this.translate.instant('customerGroups.dimatest'),
				value: CustomerGroupEnum.DIMATEST
			},
			{
				text: this.translate.instant('customerGroups.maloa'),
				value: CustomerGroupEnum.MALOA
			},
			{
				text: this.translate.instant('customerGroups.werk5'),
				value: CustomerGroupEnum.WERK5
			},
			{
				text: this.translate.instant('customerGroups.epi'),
				value: CustomerGroupEnum.EPI
			},
			{
				text: this.translate.instant('customerGroups.iamlove'),
				value: CustomerGroupEnum.IAMLOVE
			},
			{
				text: this.translate.instant('customerGroups.beetsAndRoots'),
				value: CustomerGroupEnum.BEETS_AND_ROOTS
			},
			{
				text: this.translate.instant('customerGroups.beetsAndRootsSecond'),
				value: CustomerGroupEnum.BEETS_AND_ROOTS_SECOND
			},
			{
				text: this.translate.instant('customerGroups.losteria'),
				value: CustomerGroupEnum.LOSTERIA
			},
			{
				text: this.translate.instant('customerGroups.snackElize'),
				value: CustomerGroupEnum.SNACK_ELIZE
			},
			{
				text: this.translate.instant('customerGroups.shiso'),
				value: CustomerGroupEnum.SHISO
			},
			{
				text: this.translate.instant('customerGroups.tabilo'),
				value: CustomerGroupEnum.TABILO
			},
			{
				text: this.translate.instant('customerGroups.effeGold'),
				value: CustomerGroupEnum.EFFE_GOLD
			},
			{
				text: this.translate.instant('customerGroups.lacantine'),
				value: CustomerGroupEnum.LACANTINE
			},
			{
				text: this.translate.instant('customerGroups.eam'),
				value: CustomerGroupEnum.EAM
			},
			{
				text: this.translate.instant('customerGroups.naupaka'),
				value: CustomerGroupEnum.NAUPAKA
			},
			{
				text: this.translate.instant('customerGroups.sportsbar'),
				value: CustomerGroupEnum.SPORTSBAR
			},
			{
				text: this.translate.instant('customerGroups.cigkoftem'),
				value: CustomerGroupEnum.CIGKOFTEM
			},
			{
				text: this.translate.instant('customerGroups.sushisupply'),
				value: CustomerGroupEnum.SUSHISUPPLY
			},
			{
				text: this.translate.instant('customerGroups.the_ash'),
				value: CustomerGroupEnum.THE_ASH
			},
			{
				text: this.translate.instant('customerGroups.markthalle'),
				value: CustomerGroupEnum.MARKTHALLE
			},
			{
				text: this.translate.instant('customerGroups.sashimi'),
				value: CustomerGroupEnum.SASHIMI
			},
			{
				text: this.translate.instant('customerGroups.bonboncha'),
				value: CustomerGroupEnum.BONBONCHA
			},
			{
				text: this.translate.instant('customerGroups.hulala'),
				value: CustomerGroupEnum.HULALA
			},
			{
				text: this.translate.instant('customerGroups.backwerk_schweiz'),
				value: CustomerGroupEnum.BACKWERK_SCHWEIZ
			},
			{
				text: this.translate.instant('customerGroups.teedeli'),
				value: CustomerGroupEnum.TEEDELI
			},
			{
				text: this.translate.instant('customerGroups.wohlfuhler'),
				value: CustomerGroupEnum.WOHLFUHLER
			}
		]);
	}
}
