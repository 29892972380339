import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

export const NAVIGATIONURLS = {
	monitoring: () => 'monitoring',
	venues: () => 'venues',
	createAndEditVenue: (venueId?: string) =>
		venueId
			? `create-and-edit-venue/${venueId}`
			: `create-and-edit-venue/:venueId`,
	monitoringTable: (venueId?: string, tableId?: string) =>
		`monitoring/${venueId}/table/${tableId}`,
	monitoringTableURL: () => `monitoring/table/:tableId`,
	salesStats: () => `sales-stats`,
	issueReports: () => 'issue-reports',
	login: () => 'login'
};

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	constructor(private router: Router) {}

	async monitoring() {
		await this.router.navigateByUrl(NAVIGATIONURLS.monitoring());
	}

	async login() {
		await this.router.navigateByUrl(NAVIGATIONURLS.login());
	}
	async monitoringTable(tableId: string) {
		await this.router.navigateByUrl('monitoring/table/' + tableId);
	}
	async createAndEditVenue(venueId?: string) {
		await this.router.navigateByUrl(NAVIGATIONURLS.createAndEditVenue());
	}
	async salesStats() {
		await this.router.navigateByUrl(NAVIGATIONURLS.salesStats());
	}
	async issueReports() {
		await this.router.navigateByUrl(NAVIGATIONURLS.issueReports());
	}
}
