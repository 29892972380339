import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromMonitoring from './monitoring.reducer';
import {MonitoringEffects} from './monitoring.effects';
import {MonitoringFacade} from './monitoring.facade';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(
			fromMonitoring.MONITORING_FEATURE_KEY,
			fromMonitoring.reducer
		),
		EffectsModule.forFeature([MonitoringEffects])
	],
	providers: [MonitoringFacade]
})
export class MonitoringStateModule {}
