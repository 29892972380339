import {Injectable} from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse
} from '@angular/common/http';
import {catchError, first, map, Observable, switchMap, throwError} from 'rxjs';
import {AuthFacade} from '../store/auth-state/+state/auth.facade';
import {User} from '../models/User';
import {userData} from '../store/auth-state/+state/auth.selectors';
import {environment} from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	readonly attemptHeaderName = 'X-Attempt';

	constructor(private authFacade: AuthFacade) {}
	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return this.authFacade.userData$.pipe(
			first(),

			map((user: User | null) => {
				return this.modifyRequestHeader(user?.token, req);
			}),
			switchMap((modifiedRequest: HttpRequest<any>) =>
				this.handleModifiedRequest(modifiedRequest, next)
			)
		);
	}
	private modifyRequestHeader(
		token: string | null | undefined,
		req: HttpRequest<any>
	): HttpRequest<any> {
		if (!token) {
			return req;
		}
		if (req.url.includes(environment.webAppUrl)) {
			return req;
		}
		const attempt = (
			+(req.headers.get(this.attemptHeaderName) ?? 0) + 1
		).toString();
		return req.clone({
			setHeaders: {
				Authorization: `Basic ${token}`,
				[this.attemptHeaderName]: attempt
			}
		});
	}
	private handleModifiedRequest(
		modifiedReq: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(modifiedReq).pipe(
			catchError(e => {
				if (e instanceof HttpErrorResponse && e.status === 401) {
					console.log(e, modifiedReq);
					this.authFacade.logout();
					return throwError(e);
				} else {
					return throwError(e);
				}
			})
		);
	}
}
