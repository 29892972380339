import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
	MONITORING_FEATURE_KEY,
	MonitoringState
} from './monitoring-device-page.reducer';

export const monitoringState = createFeatureSelector<MonitoringState>(
	MONITORING_FEATURE_KEY
);

export const loaded = createSelector(
	monitoringState,
	(state: MonitoringState) => state.loaded
);

export const error = createSelector(
	monitoringState,
	(state: MonitoringState) => state.error
);

export const statisticLogForDevice = createSelector(
	monitoringState,
	(state: MonitoringState) => state.statisticLogForDevice
);
export const loadedLogs = createSelector(
	monitoringState,
	(state: MonitoringState) => state.loadedLogs
);

export const selectedDevice = createSelector(
	monitoringState,
	(state: MonitoringState) => state.selectedDevice
);
export const selectVenueMonitoring = createSelector(
	monitoringState,
	(state: MonitoringState) => state.selectVenueMonitoring
);

//analytics
export const analyticsLoaded = createSelector(
	monitoringState,
	(state: MonitoringState) => state.analyticsLoaded
);
export const analyticsPagination = createSelector(
	monitoringState,
	(state: MonitoringState) => state.analyticsPagination
);

export const analyticsDevice = createSelector(
	monitoringState,
	(state: MonitoringState) => state.analyticsDevice
);
export const anyalyticsFiltersPeriod = createSelector(
	monitoringState,
	(state: MonitoringState) => state.anyalyticsFiltersPeriod
);

export const analyticsDeviceAllEvents = createSelector(
	monitoringState,
	(state: MonitoringState) => state.analyticsDeviceAllEvents
);
export const analyticsAllEventsLoaded = createSelector(
	monitoringState,
	(state: MonitoringState) => state.analyticsAllEventsLoaded
);
export const allOrders = createSelector(
	monitoringState,
	(state: MonitoringState) => state.allOrders
);
export const allOrdersLoaded = createSelector(
	monitoringState,
	(state: MonitoringState) => state.loadedAllOrders
);
export const allOrdersAnalitycsForAllDevices = createSelector(
	monitoringState,
	(state: MonitoringState) => state.allOrdersAnalitycsForAllDevices
);
