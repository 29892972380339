import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FILTERS_FEATURE_KEY, FiltersState} from './filters-data.reducer';

export const filtersState =
	createFeatureSelector<FiltersState>(FILTERS_FEATURE_KEY);
export const customerGroups = createSelector(
	filtersState,
	(state: FiltersState) => state.customerGroups
);
export const loaded = createSelector(
	filtersState,
	(state: FiltersState) => state.loaded
);
