import {Injectable} from '@angular/core';
import {
	BehaviorSubject,
	catchError,
	debounceTime,
	delayWhen,
	filter,
	map,
	Observable,
	of,
	switchMap,
	tap,
	throwError
} from 'rxjs';
import {User} from '../models/User';
import {ApiService} from './api.service';
import {TokenService} from './token.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	// private _user$ = new BehaviorSubject<User | null>(null);
	// public user$ = this._user$.pipe(
	// 	delayWhen(() => this.initiallyLoaded$.pipe(filter(v => !!v))),
	// 	debounceTime(10)
	// );
	// public isLoggedIn$ = this.user$.pipe(map(user => !!user?.token));
	// private initiallyLoaded$ = new BehaviorSubject(false);

	constructor(
		private apiService: ApiService,
		private tokenServise: TokenService
	) {}

	// login(data: {email: String; password: String}) {
	// 	return this.apiService.login(data).pipe(
	// 		tap(user => {
	// 			this.setAccountData(user);
	// 			this.tokenServise.saveBothTokens(user.token, user.refreshToken);
	// 		}),
	// 		catchError(() => {
	// 			this.setAccountData(null);
	// 			return of(null);
	// 		})
	// 	);
	// }

	// refresh(): Observable<User | null> {
	// 	const refreshToken = this.tokenServise.getRefreshToken();
	// 	if (refreshToken) {
	// 		return this.apiService.refresh(refreshToken).pipe(
	// 			tap(user => {
	// 				this.setAccountData(user);
	// 				this.tokenServise.saveBothTokens(user.token, user.refreshToken);
	// 			}),
	// 			catchError(e => {
	// 				this.setAccountData(null);
	// 				throw new Error(e);
	// 			})
	// 		);
	// 	} else {
	// 		this.initiallyLoaded$.next(true);
	// 		return of(null);
	// 	}
	// }

	// logout() {
	// 	this._user$.next(null);
	// 	this.tokenServise.removeBothTokens();
	// }

	// setAccountData(user: User | null) {
	// 	if (user) this._user$.next(user);
	// 	this.initiallyLoaded$.next(true);
	// }
}
