<div
	class="w-screen flex items-center min-h-screen p-4 bg-gray-100 lg:justify-center"
>
	<div
		class="flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1 lg:max-w-screen-md"
	>
		<div class="p-5 bg-white md:flex-1">
			<h3 class="my-4 text-2xl font-semibold text-gray-700">Account Login</h3>
			<form
				[formGroup]="loginForm"
				(ngSubmit)="onSubmit()"
				class="flex flex-col space-y-5"
			>
				<div class="flex flex-col space-y-1">
					<label for="email" class="text-sm font-semibold text-gray-500"
						>Email address</label
					>
					<input
						formControlName="email"
						placeholder="Email"
						type="email"
						id="email"
						autofocus
						class="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
					/>
				</div>
				<div class="flex flex-col space-y-1">
					<div class="flex items-center justify-between">
						<label for="password" class="text-sm font-semibold text-gray-500"
							>Password</label
						>
					</div>
					<input
						formControlName="password"
						placeholder="password"
						type="password"
						id="password"
						class="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
					/>
				</div>
				<div>
					<button
						type="submit"
						class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-primary hover:bg-purple-700 rounded-md shadow"
					>
						Log in
					</button>
				</div>
			</form>
		</div>
		<div
			class="p-4 py-6 text-white bg-gradient-to-b from-purple-800 to-pink-500 md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly"
		>
			<div
				class="my-3 text-4xl font-bold tracking-wider text-center w-full h-10 bg-no-repeat bg-center"
				style="
					background-image: url('https://dev-admin.smoothr.de/img/smoothr_logo.def9972e.svg');
				"
			></div>
			<p class="mt-6 font-normal text-center text-gray-300 md:mt-0">
				Smoothr is the shortcut for the digitization of your restaurant.
			</p>
		</div>
	</div>
</div>
