import {state} from '@angular/animations';
import {createReducer, on, Action} from '@ngrx/store';
import {Table} from 'src/app/models/Table';
import {User} from 'src/app/models/User';
import {Venue} from 'src/app/models/Venue';
import * as FiltersActions from './filters-data.actions';
import {customerGroups, loaded} from './filters-data.selectors';

export const FILTERS_FEATURE_KEY = 'filters';

export interface FiltersState {
	loaded: boolean;
	error: null | string;
	venues: Venue[];
	venueLabels: any[];
	customerGroups: {text: string; value: string}[];
	tables: Table[];
}

export const initialState: FiltersState = {
	loaded: true,
	error: null,
	venues: [],
	venueLabels: [],
	customerGroups: [],
	tables: []
};

const filtersReducer = createReducer(
	initialState,
	on(FiltersActions.loadFiltersCustomerGroup, state => ({
		...state,
		loaded: false,
		customerGroups: []
	})),
	on(FiltersActions.loadFiltersCustomerGroupSuccess, (state, response) => ({
		...state,
		loaded: true,
		customerGroups: response.response
	})),
	on(FiltersActions.loadFiltersCustomerGroupFailed, (state, error) => ({
		...state,
		loaded: true,
		error: error.error
	}))
);

export function reducer(state: FiltersState | undefined, action: Action) {
	return filtersReducer(state, action);
}
