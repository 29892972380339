import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromFilters from './filters-data.reducer';
import {FiltersEffects} from './filters-data.effects';
import {FiltersFacade} from './filters-data.facade';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(
			fromFilters.FILTERS_FEATURE_KEY,
			fromFilters.reducer
		),
		EffectsModule.forFeature([FiltersEffects])
	],
	providers: [FiltersFacade]
})
export class FiltersStateModule {}
