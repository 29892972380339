import {createReducer, on, Action} from '@ngrx/store';

import * as MonitoringActions from './monitoring.actions';
import {
	AnalyticItem,
	Device,
	DeviceFiltersParams,
	DeviceStatistic,
	getOnlineAndOrdersStatistic,
	OrderLogHistory,
	SocketDeviceInfo,
	Ticket,
	UptimeStatisticResponse,
	VenueName
} from './monitoring.models';
import {
	StatusTicketTitleEnum,
	StatusTitleEmun
} from 'src/app/models/StatusItem';

export const MONITORING_FEATURE_KEY = 'monitoring';

export interface MonitoringState {
	loaded: boolean;
	loadedDevice: boolean;
	error: null | {status: string; ok: string; message: string};
	deviceList: Device[];
	filters: DeviceFiltersParams;
	venueNames: VenueName[];
	selectedVenues: VenueName[];
	statisticForDevices: UptimeStatisticResponse[];
	loadedStatistic: boolean;
	listTickets: Ticket[];
	selectedTicket: Ticket | null;
	analyticsVenuesOrdersAndOnline: getOnlineAndOrdersStatistic | null;
	analyticsVenuesOrdersAndOnlineLoaded: boolean;
	selectedStatusDeviceFilter: StatusTitleEmun | null;
	selectedStatusTicketFilter: StatusTicketTitleEnum | null;
	deviceListFromSocket: SocketDeviceInfo[];
	showDuplicates: {number: string; venueId: string} | null;
}

export const initialState: MonitoringState = {
	loaded: true,
	error: null,
	deviceList: [],
	loadedDevice: true,
	filters: {
		deviceIds: null,
		statuses: null,
		venues: null,
		deviceTypes: null,
		customerGroups: null,
		search: null
	},
	venueNames: [],
	selectedVenues: [],
	statisticForDevices: [],
	loadedStatistic: true,
	selectedTicket: null,
	listTickets: [],
	analyticsVenuesOrdersAndOnline: null,
	analyticsVenuesOrdersAndOnlineLoaded: true,
	selectedStatusDeviceFilter: null,
	selectedStatusTicketFilter: null,
	deviceListFromSocket: [],
	showDuplicates: null
};

const monitoringReducer = createReducer(
	initialState,

	on(MonitoringActions.getDevices, (state, _) => ({
		...state,
		loaded: false,
		loadedDevice: false,
		error: null
	})),
	on(MonitoringActions.getDevicesSuccess, (state, response) => ({
		...state,
		loaded: false,
		error: null,
		loadedDevice: true,
		deviceList: response.response
	})),
	on(MonitoringActions.getDevicesFailed, (state, error) => ({
		...state,
		loaded: false,
		loadedDevice: true,
		error: error.error
	})),
	on(MonitoringActions.setFiltersDevices, (state, response) => ({
		...state,

		filters: {
			customerGroup: response.filters?.customerGroups ?? [],
			venues: response.filters?.venues ?? [],
			deviceIds: response.filters?.deviceIds ?? [],
			search: response.filters?.search ?? []
		}
	})),
	on(MonitoringActions.getVenueNames, (state, _) => ({
		...state,
		loaded: false,
		error: null,
		loadedDevice: false
	})),
	on(MonitoringActions.getVenueNamesSuccess, (state, response) => ({
		...state,
		loaded: true,
		error: null,
		loadedDevice: true,

		venueNames: response.response,
		selectedVenues: response.response
	})),
	on(MonitoringActions.getVenueNamesFailed, (state, error) => ({
		...state,
		loaded: true,
		error: error.error,
		loadedDevice: true
	})),
	on(MonitoringActions.selectedVenues, (state, response) => ({
		...state,
		loaded: false,
		selectedVenues: response.venues
	})),
	on(MonitoringActions.deleteSelectedVenue, (state, response) => ({
		...state,
		loaded: true,
		selectedVenues: state.selectedVenues.filter(
			it => it._id === response.venueId
		)
	})),
	on(MonitoringActions.getStatistic, (state, _) => ({
		...state,
		loaded: true,
		error: null,
		loadedStatistic: false
	})),
	on(MonitoringActions.getStatisticSuccess, (state, response) => ({
		...state,
		loaded: true,
		error: null,
		loadedStatistic: true,
		statisticForDevices: state.statisticForDevices.concat(...response.response)
	})),
	on(MonitoringActions.getStatisticFailed, (state, error) => ({
		...state,
		loaded: true,
		error: error.error,
		loadedStatistic: true
	})),

	//create Ticket and add to Storage
	on(MonitoringActions.createTicket, (state, _) => ({
		...state
	})),
	on(MonitoringActions.createTicketSuccesfull, (state, response) => {
		return {
			...state,
			listTickets: state.listTickets.concat([response.response]),
			deviceList: state.deviceList.map(device => {
				if (device.deviceId === response.response.deviceId) {
					let newDevice = {...device};
					newDevice.tickets = [...newDevice.tickets, response.response];
					return newDevice;
				}
				return device;
			})
		};
	}),
	on(MonitoringActions.createTicketFailed, (state, error) => ({
		...state,
		error: error.error
	})),

	//get Ticket and add to Storage
	on(MonitoringActions.getTickets, (state, _) => ({
		...state
	})),
	on(MonitoringActions.getTicketsSuccesfull, (state, response) => ({
		...state,
		listTickets: state.listTickets.concat(...response.response)
	})),
	on(MonitoringActions.getTicketsFailed, (state, error) => ({
		...state,
		error: error.error
	})),

	//load Conversation
	on(MonitoringActions.loadTicketWithConversation, (state, _) => ({
		...state,
		selectedTicket: null
	})),
	on(
		MonitoringActions.loadTicketWithConversationSuccessfull,
		(state, response) => ({
			...state,
			selectedTicket: response.response
		})
	),
	on(MonitoringActions.loadTicketWithConversationFailed, (state, error) => ({
		...state,
		error: error.error,
		selectedTicket: null
	})),

	//send Message in Conversation
	on(MonitoringActions.sendMessageInConveration, (state, _) => ({
		...state
	})),
	on(
		MonitoringActions.sendMessageInConverationSuccessfull,
		(state, response) => ({
			...state,
			selectedTicket: response.response
		})
	),
	on(MonitoringActions.sendMessageInConverationFailed, (state, error) => ({
		...state,
		error: error.error
	})),

	//upodate Ticket

	on(MonitoringActions.updateTicketSucc, (state, response) => ({
		...state,
		listTickets: state.listTickets.map(it =>
			it._id === response.response._id ? response.response : it
		)
	})),
	//TODO: Move to another State Later everything for page with Statisc

	//loadVenue
	on(MonitoringActions.loadVenue, (state, response) => ({
		...state,
		selectVenueMonitoring: null,
		analyticsDevice: []
	})),
	on(MonitoringActions.loadVenueSucc, (state, response) => ({
		...state,
		selectVenueMonitoring: response.response
	})),
	on(MonitoringActions.loadVenueFailed, (state, error) => ({
		...state,
		error: error.error
	})),
	//load Device
	on(MonitoringActions.loadDeviceById, (state, response) => ({
		...state,
		selectedDevice: null
	})),
	on(MonitoringActions.loadDeviceByIdSucc, (state, response) => ({
		...state,
		selectedDevice: response.response,
		analyticsPagination: {
			page: 1,
			limit: 15,
			total: 10
		}
	})),
	on(MonitoringActions.loadDeviceByIdFailed, (state, error) => ({
		...state,
		error: error.error
	})),
	//load Device Statistic
	on(MonitoringActions.loadStatiscticForDevice, (state, response) => ({
		...state,
		statisticLogForDevice: [],
		loadedLogs: false
	})),
	on(MonitoringActions.loadStatiscticForDeviceSucc, (state, response) => ({
		...state,
		statisticLogForDevice: response.response,
		loadedLogs: true
	})),
	on(MonitoringActions.loadStatiscticForDeviceFailed, (state, error) => ({
		...state,
		error: error.error,
		loadedLogs: true
	})),

	//get Analytics
	on(MonitoringActions.getAnalyticWithPagination, (state, response) => ({
		...state,
		analyticsLoaded: false,
		analyticsDevice: []
	})),

	//all events
	on(MonitoringActions.getAnalyticsAllEvents, (state, response) => ({
		...state,
		analyticsDeviceAllEvents: [],
		analyticsAllEventsLoaded: false
	})),

	on(MonitoringActions.getAnalyticsAllEventsFailed, (state, error) => ({
		...state,
		error: error.error,
		analyticsDeviceAllEvents: [],
		analyticsAllEventsLoaded: true
	})),

	//order history for device
	on(MonitoringActions.getAllOrders, (state, response) => ({
		...state,
		loadedAllOrders: false,
		allOrders: []
	})),
	on(MonitoringActions.getAllOrdersSucc, (state, response) => ({
		...state,
		loadedAllOrders: true,
		allOrders: response.response
	})),
	on(MonitoringActions.getAllOrdersFailed, (state, error) => ({
		...state,
		error: error.error,
		loadedAllOrders: false,
		allOrders: []
	})),
	//order history for all Devices and Venues
	on(MonitoringActions.getAnalyticsForOnlineAndOrders, (state, _) => ({
		...state,
		analyticsVenuesOrdersAndOnline: null,
		analyticsVenuesOrdersAndOnlineLoaded: false
	})),
	on(
		MonitoringActions.getAnalyticsForOnlineAndOrdersSucc,
		(state, response) => ({
			...state,
			analyticsVenuesOrdersAndOnline: response.response,
			analyticsVenuesOrdersAndOnlineLoaded: true
		})
	),
	on(
		MonitoringActions.getAnalyticsForOnlineAndOrdersFailed,
		(state, error) => ({
			...state,
			error: error.error,
			analyticsVenuesOrdersAndOnline: null,
			analyticsVenuesOrdersAndOnlineLoaded: true
		})
	),
	on(MonitoringActions.deleteDeviceSucc, (state, response) => ({
		...state,
		deviceList: state.deviceList.filter(it => it._id !== response.deviceId)
	})),
	on(MonitoringActions.deleteDeviceFailed, (state, error) => ({
		...state,
		error: error.error
	})),
	on(
		MonitoringActions.getAnalyticsForOnlineAndOrdersFailed,
		(state, error) => ({
			...state,
			error: error.error,
			analyticsVenuesOrdersAndOnline: null,
			analyticsVenuesOrdersAndOnlineLoaded: true
		})
	),
	on(
		MonitoringActions.deleteTicketHardSucc,
		(state, response) => (
			console.log('delete'),
			{
				...state,
				listTickets: state.listTickets.filter(
					it => it._id !== response.ticketId
				)
			}
		)
	),
	on(MonitoringActions.deleteDeviceFailed, (state, error) => ({
		...state,
		error: error.error
	})),
	on(MonitoringActions.deleteTicketSoftSucc, (state, response) => ({
		...state,
		listTickets: state.listTickets.map(it => {
			if (it._id === response.ticketId) {
				it = {...it, deletedAt: new Date().toISOString()};
			}
			return it;
		})
	})),
	on(MonitoringActions.deleteDeviceFailed, (state, error) => ({
		...state,
		error: error.error
	})),
	on(MonitoringActions.restoreTicket, (state, response) => ({
		...state,
		listTickets: state.listTickets.map(it => {
			if (it._id === response.ticket._id) {
				it = {...it, deletedAt: ''};
			}
			return it;
		})
	})),
	on(MonitoringActions.restoreTicketFailed, (state, error) => ({
		...state,
		error: error.error
	})),
	on(MonitoringActions.selectedStatusDeviceFilter, (state, response) => ({
		...state,
		selectedStatusDeviceFilter: response.value
	})),
	on(MonitoringActions.selectedStatusTicketFilter, (state, response) => ({
		...state,
		selectedStatusTicketFilter: response.value
	})),
	on(MonitoringActions.setDevicesSocketStatuses, (state, response) => ({
		...state,
		deviceListFromSocket: state.deviceListFromSocket
			.filter(socketDevice => socketDevice.deviceId !== response.value.deviceId)
			.concat(response.value)
	})),
	on(MonitoringActions.showDuplicates, (state, response) => ({
		...state,
		showDuplicates: response.value
	})),
	on(MonitoringActions.reset, state => ({
		...state,
		deviceList: [],
		statisticForDevices: []
	})),
	on(MonitoringActions.updateDeviceSuccess, (state, response) => ({
		...state,
		deviceList: state.deviceList.map(it => {
			if (it._id === response.device._id) {
				return {...it, deviceType: response.device.deviceType};
			}
			return it;
		})
	})),
	on(MonitoringActions.updateDeviceError, (state, error) => ({
		...state,
		error: error.error
	})),
	on(MonitoringActions.cleanAnalyticsForOnlineAndOrders, (state, error) => ({
		...state,
		analyticsVenuesOrdersAndOnline: null,
		analyticsVenuesOrdersAndOnlineLoaded: true
	}))
);
export function reducer(state: MonitoringState | undefined, action: Action) {
	return monitoringReducer(state, action);
}
