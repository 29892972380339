<div class="flex w-full relative">
	<app-sidebar
		class="w-[18%] min-w-max"
		*ngIf="isLoggedIn$ | async"
		[user]="userData$ | async"
		(logout)="logout()"
	></app-sidebar>

	<div class="flex flex-col grow w-[82%] bg-[#DFDFDF]">
		<router-outlet></router-outlet>
	</div>
</div>
