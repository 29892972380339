import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';

import * as Monitoringctions from './monitoring.actions';
import {
	Device,
	DeviceFilters,
	DeviceTypeEnum,
	SocketDeviceInfo,
	Ticket
} from './monitoring.models';
import * as MonitoringSelectors from './monitoring.selectors';
import {Tick} from 'highcharts';
import {
	StatusTicketTitleEnum,
	StatusTitleEmun
} from 'src/app/models/StatusItem';

@Injectable()
export class MonitoringFacade {
	readonly loaded$ = this.store.pipe(select(MonitoringSelectors.loaded));
	readonly error$ = this.store.pipe(select(MonitoringSelectors.error));
	readonly filters$ = this.store.pipe(select(MonitoringSelectors.filters));
	readonly venueNames$ = this.store.pipe(
		select(MonitoringSelectors.venueNames)
	);
	readonly loadedDevice$ = this.store.pipe(
		select(MonitoringSelectors.loadedDevice)
	);

	readonly loadedStatistic$ = this.store.pipe(
		select(MonitoringSelectors.loadedStatistic)
	);
	readonly statisticForDevices$ = this.store.pipe(
		select(MonitoringSelectors.statisticForDevices)
	);
	readonly devicesList$ = this.store.pipe(
		select(MonitoringSelectors.devicesList)
	);
	readonly listTickets$ = this.store.pipe(
		select(MonitoringSelectors.listTickets)
	);

	readonly selectedTicket$ = this.store.pipe(
		select(MonitoringSelectors.selectedTicket)
	);

	readonly selectedStatusDeviceFilter$ = this.store.pipe(
		select(MonitoringSelectors.selectedStatusDeviceFilter)
	);
	readonly selectedStatusTicketFilter$ = this.store.pipe(
		select(MonitoringSelectors.selectedStatusTicketFilter)
	);
	readonly deviceListFromSocket$ = this.store.pipe(
		select(MonitoringSelectors.deviceListFromSocket)
	);
	readonly analyticsVenuesOrdersAndOnlineLoaded$ = this.store.pipe(
		select(MonitoringSelectors.analyticsVenuesOrdersAndOnlineLoaded)
	);

	readonly analyticsVenuesOrdersAndOnline$ = this.store.pipe(
		select(MonitoringSelectors.analyticsVenuesOrdersAndOnline)
	);

	readonly showDuplicates$ = this.store.pipe(
		select(MonitoringSelectors.showDuplicates)
	);
	constructor(private store: Store) {}

	getStatisticDevices(filters: {
		from?: string;
		to?: string;
		deviceIds: string[];
	}) {
		this.store.dispatch(Monitoringctions.getStatistic(filters));
	}
	getVenueNames(venuesId: string[]) {
		this.store.dispatch(Monitoringctions.getVenueNames({venues: venuesId}));
	}
	getDevices(filters: DeviceFilters) {
		this.store.dispatch(Monitoringctions.getDevices(filters));
	}
	setDevicesFilters(filters: DeviceFilters) {
		this.store.dispatch(Monitoringctions.setFiltersDevices({filters}));
	}
	createTicket(ticket: {
		title: string;
		message: string;
		deviceId: string;
		venue: string;
		priority: string;
		author: {
			name: string;
			email?: string;
			phone?: string;
		};
	}) {
		this.store.dispatch(Monitoringctions.createTicket(ticket));
	}
	getTickets(deviceIds: string[]) {
		this.store.dispatch(Monitoringctions.getTickets({deviceIds}));
	}
	loadTicket(ticketId: string) {
		console.log(ticketId);
		this.store.dispatch(
			Monitoringctions.loadTicketWithConversation({ticketId})
		);
	}
	sendMessage(ticketId: string, message: {name: string; message: string}) {
		this.store.dispatch(
			Monitoringctions.sendMessageInConveration({ticketId, message})
		);
	}
	updateTicket(ticketId: string, status: string) {
		this.store.dispatch(Monitoringctions.updateTicket({ticketId, status}));
	}
	getDeviceById(deviceId: string) {
		this.store.dispatch(Monitoringctions.loadDeviceById({deviceId}));
	}
	loadStatiscticForDevice(
		deviceId: string,
		search?: string,
		period?: {from: string; to: string}
	) {
		this.store.dispatch(
			Monitoringctions.loadStatiscticForDevice({deviceId, search, period})
		);
	}
	loadVenue(venueId: string) {
		this.store.dispatch(Monitoringctions.loadVenue({venueId}));
	}
	changeAnalyticsPager(pager: {page: number; limit: number}) {
		this.store.dispatch(Monitoringctions.changeAnalyticsPagination(pager));
	}
	changeAnalyticsPerid(period: {from: string; to: string}) {
		this.store.dispatch(Monitoringctions.changeAnalyticsPeriod(period));
	}
	getAllEvents(data: {deviceId: string}) {
		this.store.dispatch(
			Monitoringctions.getAnalyticsAllEvents({deviceId: data.deviceId})
		);
	}
	getAnayticsToDevice(data: {deviceIds: string[]}) {
		this.store.dispatch(
			Monitoringctions.getAnalyticWithPagination({
				deviceIds: data.deviceIds
			})
		);
	}
	getAllOrder(data: {deviceId: string; from?: string; to?: string}) {
		this.store.dispatch(Monitoringctions.getAllOrders(data));
	}
	getOnlineAndOrdersStatistic(data: {venues: string[]}) {
		this.store.dispatch(Monitoringctions.getAnalyticsForOnlineAndOrders(data));
	}
	deleteDevice(deviceId: string) {
		this.store.dispatch(Monitoringctions.deleteDevice({deviceId}));
	}
	deleteTicketSoft(ticketId: string) {
		this.store.dispatch(Monitoringctions.deleteTicketSoft({ticketId}));
	}
	deleteTicketHard(ticketId: string) {
		this.store.dispatch(Monitoringctions.deleteTicketHard({ticketId}));
	}
	restoreTicket(ticket: Ticket) {
		this.store.dispatch(Monitoringctions.restoreTicket({ticket}));
	}
	selectedStatusDeviceFilter(value: StatusTitleEmun | null) {
		this.store.dispatch(Monitoringctions.selectedStatusDeviceFilter({value}));
	}

	selectedStatusTicketFilter(value: StatusTicketTitleEnum | null) {
		this.store.dispatch(Monitoringctions.selectedStatusTicketFilter({value}));
	}
	updateDeviceSocketEventInStore(value: SocketDeviceInfo) {
		this.store.dispatch(Monitoringctions.setDevicesSocketStatuses({value}));
	}
	showDuplicates(value: {value: {venueId: string; number: string} | null}) {
		this.store.dispatch(Monitoringctions.showDuplicates(value));
	}
	reset() {
		this.store.dispatch(Monitoringctions.reset({reset: null}));
	}
	updateDeviceType(deviceId: string, deviceType: DeviceTypeEnum) {
		this.store.dispatch(
			Monitoringctions.updateDeviceType({deviceId, deviceType})
		);
	}
	cleanOnlineAndOrderAnalylics() {
		this.store.dispatch(Monitoringctions.cleanAnalyticsForOnlineAndOrders());
	}
}
