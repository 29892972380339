import {createFeatureSelector, createSelector} from '@ngrx/store';
import {MONITORING_FEATURE_KEY, MonitoringState} from './monitoring.reducer';

export const monitoringState = createFeatureSelector<MonitoringState>(
	MONITORING_FEATURE_KEY
);

export const devicesList = createSelector(
	monitoringState,
	(state: MonitoringState) => state.deviceList
);
export const venueNames = createSelector(
	monitoringState,
	(state: MonitoringState) => state.venueNames
);
export const loaded = createSelector(
	monitoringState,
	(state: MonitoringState) => state.loaded
);

export const filters = createSelector(
	monitoringState,
	(state: MonitoringState) => state.filters
);
export const error = createSelector(
	monitoringState,
	(state: MonitoringState) => state.error
);
export const statisticForDevices = createSelector(
	monitoringState,
	(state: MonitoringState) => state.statisticForDevices
);
export const loadedStatistic = createSelector(
	monitoringState,
	(state: MonitoringState) => state.loadedStatistic
);

export const listTickets = createSelector(
	monitoringState,
	(state: MonitoringState) => state.listTickets
);
export const selectedTicket = createSelector(
	monitoringState,
	(state: MonitoringState) => state.selectedTicket
);

export const analyticsVenuesOrdersAndOnline = createSelector(
	monitoringState,
	(state: MonitoringState) => state.analyticsVenuesOrdersAndOnline
);

export const selectedStatusDeviceFilter = createSelector(
	monitoringState,
	(state: MonitoringState) => state.selectedStatusDeviceFilter
);

export const selectedStatusTicketFilter = createSelector(
	monitoringState,
	(state: MonitoringState) => state.selectedStatusTicketFilter
);

export const deviceListFromSocket = createSelector(
	monitoringState,
	(state: MonitoringState) => state.deviceListFromSocket
);
export const analyticsVenuesOrdersAndOnlineLoaded = createSelector(
	monitoringState,
	(state: MonitoringState) => state.analyticsVenuesOrdersAndOnlineLoaded
);
export const loadedDevice = createSelector(
	monitoringState,
	(state: MonitoringState) => state.loadedDevice
);
export const showDuplicates = createSelector(
	monitoringState,
	(state: MonitoringState) => state.showDuplicates
);
